<!--综合电压-->
<template>
    <div style="margin-top: 30px" class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
    <div v-if="integratedVoltageShow" class="ui-height-100" style="margin-left: 20px; margin-right: 20px; margin-top: -20px">
    <ex-search-table-pagination
        ref="searchTable"
        :fetch = "getDataList"
        :columns="columns"
        :formOptions="formOptions"
        :searchWhenSortChange="true"
    >
    </ex-search-table-pagination>
    </div>
    </div>
</template>
<script>
import * as dayjs from 'dayjs';
import log from '../log/log';
import numeral from 'numeral';
export default {
    name: 'IntegratedVoltage',
    data() {
        return {
            voltageMonitoringShow: false,
            integratedVoltageShow: true,
            dataList: [],
            data: {
                date: '',
                allPercent: 0,
                aCount: 0,
                bCount: 0,
                cCount: 0,
                dCount: 0,
                aData: {},
                bData: {},
                cData: {},
                dData: {},
                aYearPercent: {},
                bYearPercent: {},
                cYearPercent: {},
                dYearPercent: {},
            },
        };
    },
    computed: {
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'startTime',
                        itemType: 'date',
                        placeholder: '开始时间',
                    },
                    {
                        prop: 'endTime',
                        itemType: 'date',
                        placeholder: '结束时间',
                    },
                ],
            };
        },
        columns() {
            return [
                {prop: 'date', label: '日期'},
                {prop: 'allPercent', label: '综合电压合格率-本日/月'},
                {prop: 'allPercent', label: '综合电压合格率-年度累计'},
                {prop: 'percent',
                    label: 'A类—本日/月合格率(%)',
                    formatter(row) {
                        if (row.aData.data != null) {
                            return numeral(numeral(row.aData.data4.value).value()).format('0.000');
                        }
                    }},
                {prop: 'aCount', label: 'A类—本日/月测点数'},
                {prop: 'aYearPercent',
                    label: 'A类—累计合格率(%)',
                    formatter(row) {
                        if (row.aYearPercent.data != null) {
                            return numeral(numeral(row.aYearPercent.data.value).value()).format('0.000');
                        }
                    }},
                {prop: 'aCount', label: 'A类—累计测点数'},
                {prop: 'device',
                    label: 'B类—本日/月合格率(%)',
                    formatter(row) {
                        if (row.bData.data != null) {
                            return numeral(numeral(row.bData.data4.value).value()).format('0.000');
                        }
                    }},
                {prop: 'bCount', label: 'B类—本日/月测点数'},
                {prop: 'bYearPercent',
                    label: 'B类—累计合格率(%)',
                    formatter(row) {
                        if (row.bYearPercent.data != null) {
                            return numeral(numeral(row.bYearPercent.value).value()).format('0.000');
                        }
                    }},
                {prop: 'bCount', label: 'B类—累计测点数'},
                {prop: 'device',
                    label: 'C类—本日/月合格率(%)',
                    formatter(row) {
                        if (row.cData.data != null) {
                            return numeral(numeral(row.cData.data4.value).value()).format('0.000');
                        }
                    }},
                {prop: 'cCount', label: 'C类—本日/月测点数'},
                {prop: 'cYearPercent',
                    label: 'C类—累计合格率(%)',
                    formatter(row) {
                        if (row.cYearPercent.data != null) {
                            return numeral(numeral(row.cYearPercent.data.value).value()).format('0.000');
                        }
                    }},
                {prop: 'cCount', label: 'C类—累计测点数'},
                {prop: 'device',
                    label: 'D类—本日/月合格率(%)',
                    formatter(row) {
                        if (row.dData != null) {
                            return numeral(numeral(row.dData.data4.value).value()).format('0.000');
                        }
                        return '';
                    }},
                {prop: 'dCount', label: 'D类—本日/月测点数'},
                {prop: 'dYearPercent',
                    label: 'D类—累计合格率(%)',
                    formatter(row) {
                        if (row.dYearPercent.data != null) {
                            return numeral(numeral(row.dYearPercent.data.value).value()).format('0.000');
                        }
                    }},
                {prop: 'dCount', label: 'D类—累计测点数'},
            ];
        },
    },
    methods: {
        getDataList (data) {
            return new Promise(async(resolve) => {
                this.data = {
                    date: '',
                    allPercent: 0,
                    aCount: 0,
                    bCount: 0,
                    cCount: 0,
                    dCount: 0,
                    aData: {},
                    bData: {},
                    cData: {},
                    dData: {},
                    aYearPercent: {},
                    bYearPercent: {},
                    cYearPercent: {},
                    dYearPercent: {},
                };
                if (data.startTime === null || data.startTime === '') {
                    data.startTime = dayjs(dayjs().hour(0).minute(0).second(0)).format('YYYY-MM-DD');
                }
                if (data.endTime === null || data.endTime === '') {
                    data.endTime = dayjs().format('YYYY-MM-DD');
                }

                await this.getmonitorList();
                await this.yearPercent();
                await this.getElk(data.startTime, data.endTime);
                resolve({
                    data: {
                        records: this.dataList
                    }
                });
            });
        },
        async getmonitorList() {
            let res = await this.$client.powerMonitorAll();
            res.data.forEach(i => {
                if (i.monitorSpotTypeId === 'monitor_spot_type_1') {
                    this.data.aCount++;
                }
                if (i.monitorSpotTypeId === 'monitor_spot_type_2') {
                    this.data.bCount++;
                }
                if (i.monitorSpotTypeId === 'monitor_spot_type_3') {
                    this.data.cCount++;
                }
                if (i.monitorSpotTypeId === 'monitor_spot_type_4') {
                    this.data.dCount++;
                }
            });
        },
        async yearPercent() {
            let endTime = dayjs(dayjs().add(1, 'day').hour(0).minute(0).second(0).millisecond(0)).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            let params = {aggs: {data: {terms: {field: 'monitorSpotTypeId.keyword', order: {_key: 'asc'}, size: 100}, aggs: {data: {avg: {field: '日合格率'}}}}}, size: 0, fields: [{field: 'monitorTime', format: 'date_time'}], script_fields: {}, stored_fields: ['*'], runtime_mappings: {}, _source: {excludes: []}, query: {bool: {must: [], filter: [{match_all: {}}, {range: {monitorTime: {gte: '2021-11-24T16:00:00.000Z', lte: endTime, format: 'strict_date_optional_time'}}}], should: [], must_not: []}}};
            let res = await this.$client.listMointorInfo(params);
            let data = res.data.aggregations.data.buckets;
            data.forEach(i => {
                if (i.key === 'monitor_spot_type_1') {
                    this.data.aYearPercent = i;
                }
                if (i.key === 'monitor_spot_type_2') {
                    this.data.bYearPercent = i;
                }
                if (i.key === 'monitor_spot_type_3') {
                    this.data.cYearPercent = i;
                }
                if (i.key === 'monitor_spot_type_4') {
                    this.data.dYearPercent = i;
                }
            });
        },
        async getElk(start, end) {
            let startTime = dayjs(dayjs(start).hour(0).minute(0).second(0)).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            let endTime = dayjs(dayjs(end).add(1, 'day').hour(0).minute(0).second(0)).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            let params = {aggs: {data: {terms: {field: 'monitorSpotTypeId.keyword', order: {data: 'desc'}, size: 100}, aggs: {data: {avg: {field: '日A相合格率'}}, data2: {avg: {field: '日B相合格率'}}, data3: {avg: {field: '日C相合格率'}}, data4: {avg: {field: '日合格率'}}}}}, size: 0, fields: [{field: 'monitorTime', format: 'date_time'}], script_fields: {}, stored_fields: ['*'], runtime_mappings: {}, _source: {excludes: []}, query: {bool: {must: [], filter: [{match_all: {}}, {range: {monitorTime: {gte: startTime, lte: endTime, format: 'strict_date_optional_time'}}}], should: [], must_not: []}}};
            let res = await this.$client.listMointorInfo(params);
            let data = res.data.aggregations.data.buckets;
            let aPercent = 0;
            let bcdPercent = 0;
            let count = 0;
            data.forEach(i => {
                if (i.key === 'monitor_spot_type_1') {
                    //this.data.aCount++;
                    aPercent = i.data4.value;
                    this.data.aData = i;
                }
                if (i.key === 'monitor_spot_type_2') {
                    //this.data.bCount++;
                    this.data.bData = i;
                }
                if (i.key === 'monitor_spot_type_3') {
                    //this.data.cCount++;
                    this.data.cData = i;
                }
                if (i.key === 'monitor_spot_type_4') {
                    //this.data.dCount++;
                    this.data.dData = i;
                }
                if (i.key !== 'monitor_spot_type_1') {
                    if (i.data4.value) {
                        count++;
                        bcdPercent += i.data4.value;
                    }
                }
            });
            let bcd = numeral(bcdPercent).divide(count).multiply(0.5).value();
            this.data.allPercent = numeral(numeral(aPercent).multiply(0.5).add(bcd).value()).format('0.000');
            this.data.date = start + ' ~ ' + end;
            this.dataList = [this.data];
        }
    },
};
</script>

<style scoped>

</style>
