<!--电压-->
<template>
    <div style="margin-top: 30px" class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div style="margin-left: 30px; margin-bottom: 10px;">
            <el-button type="primary" size="mini" style="border-radius: 4px" @click="voltageMonitoringIsShow()">
                监测电压
            </el-button>
        <el-button type="primary" size="mini" style="border-radius: 4px" @click="integratedVoltageIsShow()">
            综合电压
        </el-button>
        </div>
        <div v-if="isShow" class="ui-height-100" style="margin-left: 20px; margin-right: 20px; margin-top: -20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch = "getDataList"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
            >
                <template slot="append">
                    <el-table-column label="操作" align="center" fixed="right" width="120%">
                        <template slot-scope="scope">
                            <el-button style="margin-top: 5px" type="primary" size="mini" @click="setDataForm(scope.row)"> 原因分析 </el-button>
                            <el-button style="margin-top: 5px" type="primary" size="mini" @click="clickTo(scope.row)"> 曲线图 </el-button>
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
        <IntegratedVoltage v-if="integratedVoltageShow"></IntegratedVoltage>
        <el-dialog
            :title="'原因分析'"
            :close-on-click-modal="false"
            :visible.sync="visible">
            <el-form :model="dataForm" ref="dataForm" label-width="120px">
                <el-form-item label="原因分析" prop="title" style="color: #ffffff">
                    <el-input
                        style="width: 100%; "
                        v-model="dataForm.cause"
                        type="textarea"
                        placeholder="原因分析">
                    </el-input>
                </el-form-item>
                <el-form-item label="处理措施" prop="title" style="color: #ffffff">
                    <el-input
                        style="width: 100%; "
                        v-model="dataForm.measure"
                        type="textarea"
                        placeholder="处理措施">
                    </el-input>
                </el-form-item>
                <el-form-item label="完成情况" prop="title" style="color: #ffffff">
                    <el-input
                        style="width: 100%; "
                        v-model="dataForm.status"
                        type="textarea"
                        placeholder="完成情况">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="saveOrUpdata()">确定</el-button>
    </span>
        </el-dialog>
    </div>
</template>
<script>
import IntegratedVoltage from './IntegratedVoltage';
import * as dayjs from 'dayjs';
import numeral from 'numeral';
import {rejectWorkTicketFactory} from '../../client';
export default {
    components: {IntegratedVoltage},
    data() {
        return {
            integratedVoltageShow: false,
            isShow: true,
            visible: false,
            dataList: [],
            monitorType: [], //监测类型
            monitorSpotType: [], //监测点类型
            dataForm: {
                monitorId: '',
                cause: '',
                measure: '',
                status: '',
            }
        };
    },
    computed: {
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'startTime',
                        itemType: 'date',
                        clearable: true,
                        filterable: true,
                        placeholder: '开始时间',
                    },
                    {
                        prop: 'endTime',
                        itemType: 'date',
                        clearable: true,
                        filterable: true,
                        placeholder: '结束时间',
                    },
                ],
            };
        },
        columns() {
            return [
                {prop: 'monitorTime',
                    label: '日期',
                    formatter(row) {
                        if (row.monitorTime) {
                            return dayjs(row.monitorTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD');
                        }
                    }},
                {prop: 'name', label: '监测点名称'},
                {prop: 'monitorSpotTypeId',
                    label: '监测点类型',
                    formatter: function (row, cloums, data, index) {
                        if (row.monitorSpotTypeId) {
                            let obj = this.monitorSpotType.filter((item) => item.code === row.monitorSpotTypeId);
                            return obj && obj.length > 0 ? obj[0].name : '---';
                        }
                        return '---';
                    }.bind(this)},
                {prop: 'busines_monitor_type_1_2', label: '电压等级(kV)'},
                {prop: 'maxValue',
                    label: '合格电压范围',
                    formatter(row) {
                        return row.minValue + ' ' + '~' + ' ' + row.maxValue;
                    }},
                {prop: 'address', label: '监测点位置'},
                {prop: 'nominalVoltage',
                    label: '额定电压',
                    formatter(row) {
                        return numeral(row.nominalVoltage ? row.nominalVoltage : 0).format('0.00');
                        // return numeral(row.nominalVoltage).format('0.00');
                    }},
                {prop: '合格率(%)',
                    label: '合格率(%)',
                    formatter(row) {
                        if (row) {
                            return numeral(row['日合格率'] ? row['日合格率'] : 0).format('0.00');
                            // return numeral(row['日合格率']).format('0.00');
                        }
                    }},
                {prop: '日最高值',
                    label: '最大电压(kV/V)',
                    formatter(row) {
                        if (row) {
                            if (row.monitorSpotTypeId === 'monitor_spot_type_4') {
                                return numeral(row['日最高值'] ? row['日最高值'] : 0).multiply(Math.sqrt(3)).format('0.00');
                                // return numeral(row['日最高值']).multiply(Math.sqrt(3)).format('0.00');
                            }
                            if (row.monitorSpotTypeId === 'monitor_spot_type_1' || row.monitorSpotTypeId === 'monitor_spot_type_3') {
                                if (row.monitorSpotTypeId === 'monitor_spot_type_1') {
                                    return numeral(row['日最高值'] ? row['日最高值'] : 0).divide(10).multiply(Math.sqrt(3)).format('0.00');
                                    // return numeral(row['日最高值']).divide(10).multiply(Math.sqrt(3)).format('0.00');
                                }
                                return numeral(row['日最高值'] ? row['日最高值'] : 0).divide(10).format('0.00');
                                // return numeral(row['日最高值']).divide(10).format('0.00');
                            }
                            return numeral(row['日最高值'] ? row['日最高值'] : 0).format('0.00');
                            // return numeral(row['日最高值']).format('0.00');
                        }
                    }},
                {prop: 'RealTimeData_177.最高电压出现时间最大值',
                    label: '最大值出现时间',
                    formatter(row) {
                        if (row) {
                            return dayjs(row['日最高值出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD HH:mm:ss');
                        }
                    }},
                {prop: 'RealTimeData_177.最低电压最小值',
                    label: '最小电压(kV/V)',
                    formatter(row) {
                        if (row) {
                            if (row.monitorSpotTypeId === 'monitor_spot_type_4') {
                                return numeral(row['日最低值'] ? row['日最低值'] : 0).multiply(Math.sqrt(3)).format('0.00');
                                // return numeral(row['日最低值']).multiply(Math.sqrt(3)).format('0.00');
                            }
                            if (row.monitorSpotTypeId === 'monitor_spot_type_1' || row.monitorSpotTypeId === 'monitor_spot_type_3') {
                                if (row.monitorSpotTypeId === 'monitor_spot_type_1') {
                                    return numeral(row['日最低值'] ? row['日最低值'] : 0).divide(10).multiply(Math.sqrt(3)).format('0.00');
                                    // return numeral(row['日最低值']).divide(10).multiply(Math.sqrt(3)).format('0.00');
                                }
                                return numeral(row['日最低值'] ? row['日最低值'] : 0).divide(10).format('0.00');
                                // return numeral(row['日最低值']).divide(10).format('0.00');
                            }
                            return numeral(row['日最低值'] ? row['日最低值'] : 0).format('0.00');
                            // return numeral(row['日最低值']).format('0.00');
                        }
                    }},
                {prop: 'RealTimeData_177.最低电压出现时间最大值',
                    label: '最小值出现时间',
                    formatter(row) {
                        if (row) {
                            return dayjs(row['日最低值出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD HH:mm:ss');
                        }
                    }},
                {prop: 'device',
                    label: '平均电压(kV/V)',
                    formatter(row) {
                        if (row) {
                            if (row.monitorSpotTypeId === 'monitor_spot_type_4') {
                                return numeral(numeral(row['A相电压'] ? row['A相电压'] : 0)
                                    .add(row['B相电压'] ? row['B相电压'] : 0)
                                    .add(row['C相电压'] ? row['C相电压'] : 0)
                                    .divide(3))
                                    .multiply(Math.sqrt(3)).format('0.00');
                                // return numeral(numeral(row['A相电压']).add(row['B相电压']).add(row['C相电压']).divide(3)).multiply(Math.sqrt(3)).format('0.00');
                            }
                            if (row.monitorSpotTypeId === 'monitor_spot_type_1' || row.monitorSpotTypeId === 'monitor_spot_type_3') {
                                if (row.monitorSpotTypeId === 'monitor_spot_type_1') {
                                    return numeral(numeral(row['A相电压'] ? row['A相电压'] : 0)
                                        .add(row['B相电压'] ? row['B相电压'] : 0)
                                        .add(row['C相电压'] ? row['C相电压'] : 0)
                                        .divide(3).divide(10)).multiply(Math.sqrt(3)).format('0.00');
                                    // return numeral(numeral(row['A相电压']).add(row['B相电压']).add(row['C相电压']).divide(3).divide(10)).multiply(Math.sqrt(3)).format('0.00');
                                }
                                if (row.Identity === '532953870') {
                                    return numeral(numeral(row['A相电压'] ? row['A相电压'] : 0)
                                        .add(row['C相电压'] ? row['C相电压'] : 0)
                                        .divide(2).divide(10).value()).format('0.00');
                                    // return numeral(numeral(row['A相电压']).add(row['C相电压']).divide(2).divide(10).value()).format('0.00');
                                }
                                return numeral(numeral(row['A相电压'] ? row['A相电压'] : 0)
                                    .add(row['B相电压'] ? row['B相电压'] : 0)
                                    .add(row['C相电压'] ? row['C相电压'] : 0)
                                    .divide(3).divide(10).value()).format('0.00');
                                // return numeral(numeral(row['A相电压']).add(row['B相电压']).add(row['C相电压']).divide(3).divide(10).value()).format('0.00');
                            }
                            return numeral(numeral(row['A相电压'] ? row['A相电压'] : 0)
                                .add(row['B相电压'] ? row['B相电压'] : 0)
                                .add(row['C相电压'] ? row['C相电压'] : 0)
                                .divide(3).value()).format('0.00');
                            // return numeral(numeral(row['A相电压']).add(row['B相电压']).add(row['C相电压']).divide(3).value()).format('0.00');
                        }
                    }},
                {prop: '不合格时间(分钟)',
                    label: '不合格时间(分钟)',
                    formatter(row) {
                        if (row) {
                            // return numeral(numeral(row['日超上限时间']).add(row['日超下限时间']).value()).format('0.00');
                            return numeral(numeral(row['日超上限时间'] ? row['日超上限时间'] : 0)
                                .add(row['日超下限时间'] ? row['日超下限时间'] : 0)
                                .value()).format('0.00');
                        }
                    }},
                {prop: '日超上限时间', label: '超上限时间'},
                {prop: '日超下限时间', label: '超下限时间'},
                {prop: '日超上限时间',
                    label: '超上限率(%)',
                    formatter(row) {
                        if (row) {
                            return numeral(numeral(row['日超上限时间'] ? row['日超上限时间'] : 0)
                                .divide(row['日监测时间'] ? row['日监测时间'] : 0)
                                .multiply(100).value()).format('0.00');
                            // return numeral(numeral(row['日超上限时间']).divide(row['日监测时间']).multiply(100).value()).format('0.00');
                        }
                    }},
                {prop: '日超下限时间',
                    label: '超下限率(%)',
                    formatter(row) {
                        if (row) {
                            return numeral(numeral(row['日超下限时间'] ? row['日超下限时间'] : 0)
                                .divide(row['日监测时间'] ? row['日监测时间'] : 0)
                                .multiply(100).value()).format('0.00');
                            // return numeral(numeral(row['日超下限时间']).divide(row['日监测时间']).multiply(100).value()).format('0.00');
                        }
                    }},
                {prop: '日监测时间',
                    label: '总统计时间(分钟)',
                    formatter(row) {
                        if (row) {
                            return numeral(row['日监测时间'] ? row['日监测时间'] : 0).format('0.00');
                            // return numeral(row['日监测时间']).format('0.00');
                        }
                    }},
                {prop: 'device',
                    label: '原因分析',
                    formatter(row) {
                        if (row.abnormityInfo != null) {
                            return row.abnormityInfo.cause;
                        }
                    }},
                {prop: 'device',
                    label: '处理措施',
                    formatter(row) {
                        if (row.abnormityInfo != null) {
                            return row.abnormityInfo.status;
                        }
                    }},
            ];
        },
    },
    created() {
        let codes = ['busines_monitor_type', 'monitor_spot_type'];
        this.$client.listDictionaryChildrenTrees(codes).then((res) => {
            this.monitorSpotType = res.data.monitor_spot_type.children;
            this.monitorType = res.data.busines_monitor_type.children;
        });
    },
    methods: {
        saveOrUpdata() {
            this.$client.powerMonitorBaseInformationsrAdd(this.dataForm).then(res => {
                if (res.code === 0) {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                    });
                    this.visible = false;
                }
            });
        },
        setDataForm(row) {
            this.visible = true;
            this.dataForm.monitorId = row.id;
        },
        async getDataList (data) {
            if (data.startTime === null || data.startTime === '') {
                data.startTime = dayjs(dayjs().hour(0).minute(0).second(0).millisecond(0));
            }
            if (data.endTime === null || data.endTime === '') {
                data.endTime = dayjs();
            }
            let startTime = dayjs(data.startTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            let endTime = dayjs(data.endTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            let params = {size: 500, sort: [{monitorTime: {order: 'desc', unmapped_type: 'boolean'}}], version: true, fields: [{field: '*', include_unmapped: 'true'}, {field: 'monitorTime', format: 'strict_date_optional_time'}], aggs: {2: {date_histogram: {field: 'monitorTime', fixed_interval: '3h', time_zone: 'Asia/Shanghai', min_doc_count: 1}}}, script_fields: {}, stored_fields: ['*'], runtime_mappings: {}, _source: false, query: {bool: {must: [], filter: [{match_all: {}}, {range: {monitorTime: {gte: startTime, lte: endTime, format: 'strict_date_optional_time'}}}], should: [], must_not: []}}};
            let res = await this.$client.listMointorInfo(params);
            let result = [];
            let monitorIdList = [];
            res.data.hits.hits.forEach(i => {
                for (let key in i.fields) {
                    i.fields[key] = i.fields[key][0];
                    i.fields.id = i._id;
                }
                monitorIdList.push(i.fields.id);
                result.push(i.fields);
            });
            let res1 = await this.$client.powerMonitorBaseInformationsList(monitorIdList);
            let list = res1.data.records;
            result.forEach(i => {
                list.forEach(s => {
                    if (i.id === s.monitorId) {
                        i.abnormityInfo = s;
                    }
                });
            });
            console.log(result);
            return {
                data: {
                    records: result
                }
            };
        },
        /**
         * 综合电压
         */
        integratedVoltageIsShow() {
            this.isShow = false;
            this.integratedVoltageShow = true;
        },
        voltageMonitoringIsShow() {
            this.isShow = true;
            this.integratedVoltageShow = false;
        },
        clickTo(row) {
            let obj = this.monitorSpotType.filter((item) => item.code === row.monitorSpotTypeId)[0];
            let identity = row.Identity;
            let unit = row.unit;
            let max = row.maxValue;
            let min = row.minValue;
            let powerMonitorName = obj.name + '-' + row.name;
            let src =
                `https://bs.basic.test.ynlxkj.cn/big-screen?id=d6017ca9-2232-492c-a22d-c351400c25c9&identity=${identity}&unit=${unit}&max=${max}&min=${min}&powerMonitorName=${powerMonitorName}`;
            let iframeSrc = '/external/HtmlPanel?url=' + encodeURIComponent(src);
            this.isShow = false;
            this.$router.push(iframeSrc);
        },
    },
};
</script>
<style lang="less" scoped>
.box-btn {
    position: relative;
    left: 92%;
    top: 2.1em;
}
</style>
